.grid-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-background {
  background-color: rgb(249, 249, 249);
  padding: 20px;
  width: 100%;
  height: fit-content;
  margin-bottom: 50px;
}

.health-line-note {
  padding: 10px 20px;
  margin: 40px 20px 20px;
  background-color: #fdf6f6;
}



.mainBoxLabel {
  padding: 10px 20px;
  font-weight: bolder;
  position: fixed;
  width: 100%;
  background: #fff;
}

.detail-component {
  background-color: #f9f9f9;
  padding: 10px;
  margin: 15px 20px;
}
.left_grid {
  overflow: auto;
  width: 72%;
}
.summary_grid {
  overflow: auto;
}
.right_grid {
  width: 25%;
  position: fixed;
  right: 10px;
}
.detail-component h3,
.recent-component h3 {
  margin: 10px 0;
}

.detail-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.detail-label {
  font-weight: 600;
}

.detail-value {
  padding: 2px;
}

.recent-component {
  background-color: #f9f9f9;
  padding: 10px;
  margin: 20px;
  height: 30vh;
  overflow-y: auto;
}

.white-background {
  background-color: white;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.note-actions {
  display: flex;
  justify-content: space-between;
}

.detail {
  font-size: bold;
}

.detail-red {
  color: red;
  font-weight: bold;
}
.case_titile {
  font-size: 17px;
  font-weight: bold;
  margin: 5px 0;
}
.client-grid {
  display: flex;
  justify-content: space-between;
}